<template>
  <div class="mt-n2">
    <menu-tabs
      @setMenuOption="setMenuOption" 
      @selectedProperty="selectedProperty"
    ></menu-tabs>

    <div class="mr-6" :class="!isPropertyLevel ? 'mt-5' : 'mt-n1'">

      <hb-data-table-header
        left-columns="6"
        right-columns="6"
      >
        <template v-slot:header>
          {{ header }}
        </template>
        <template v-slot:description>
          {{ subheader }}
        </template> 
        <template v-slot:actions>
          <div v-if="!isPropertyLevel && isDocuments">
            <hb-btn color="secondary" small class="mr-3" @click="downloadGuide(GUIDES.DOCUMENT_TOKENS_LIST)">Document Tokens List</hb-btn>
            <hb-btn color="secondary" small class="mr-3" @click="downloadGuide(GUIDES.PRICING_TABLE_INSTRUCTIONS)">Pricing Table Instructions</hb-btn>
            <hb-btn color="secondary" small @click="uploadDocument">Upload Document</hb-btn>
            <!-- <hb-btn color="secondary" small @click="nav_drawer = true">Upload Document</hb-btn> -->
          </div>
        </template>
      </hb-data-table-header>
      
      <v-row class="justify-center ma-0 pa-0" v-if="!isDocumentsLoaded">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-row>

      <document-library v-if="isDocumentsLoaded && isDocuments" :isPropertyLevel="isPropertyLevel" :property="property" @editItem="uploadDocument" @onDocumentsUpdated="fetchDocumentsList" :level="level"></document-library>
      <hb-empty-state
        v-else-if="isDocumentsLoaded && showEmptyStateContainer"
        :message="emptyStateMessage"
        btn-txt="Add Document"
        :show-btn="isShowBtn"
        :disabled="false"
        @clicked="showTemplateForm = true"
      ></hb-empty-state>
      <upload-document-modal v-if="is_upload_document" v-model="is_upload_document" :selected="selected" @onDocumentsUpdated="fetchDocumentsList"></upload-document-modal>

    </div>
    
    <!-- <v-navigation-drawer
            v-model="nav_drawer"
            absolute
            class="navigation-drawer-on-settings-z-203"
            style="top: 60px; bottom: 0px"
            width="100%"
            stateless
            temporary
            
        >
        <p>This is a test <v-icon @click="nav_drawer = false">mdi-close</v-icon></p> 
    </v-navigation-drawer> -->


  </div>
</template> 

<script type="text/babel">
import DocumentLibrary from './DocumentLibrary';
import UploadDocumentModal from './UploadDocumentModal';
import MenuTabs from '../Common/MenuTabs';
import api from '../../../assets/api.js';
import { EventBus } from '../../../EventBus.js';
import {mapGetters, mapActions, mapMutations} from 'vuex';
import { notificationMixin } from '@/mixins/notificationMixin.js';
import GUIDES from "@/constants/guides.js";
import TemplateForm from './TemplateForm.vue';
export default {
  name: "DocumentManagerIndex",
  data() {
    return {
    
      level: 'corporate',
      property: {},
      nav_drawer: false,
      is_upload_document: false,
      menuOptoins: [
        {
          key: "corporate",
          label: "Corporate Settings",
        },
        {
          key: "property",
          label: "Property Settings",
        }
      ],
      selected: {},
      documentsLoading: false
    }
  },
  components: {
    DocumentLibrary,
    UploadDocumentModal,
    MenuTabs,
    TemplateForm
  },
  mixins: [notificationMixin],
  async created() {
    this.GUIDES = GUIDES;
    this.documentsLoading = true;
    await this.fetchDocumentsList();
    await this.fetchUnSignedDocumentTypes();
    this.documentsLoading = false;
  },
  computed: {
    ...mapGetters({
      getSettingsData: 'documentsStore/getSettingsData',
    }),
    isPropertyLevel() {
      return this.level == 'property' ? true : false;
    },
    header() {
      return this.isPropertyLevel ? 'Documents Per Property' : 'Document Library';
    },
    subheader() {
      return this.isPropertyLevel ? 'Assign a document from this library to a specific property.' : 'View and edit information about the documents you upload.';
    },
    showEmptyStateContainer() {
      return this.isPropertyLevel ? true : (this.getSettingsData[this.level] && this.getSettingsData[this.level].length) ? false : true;
    },
    isShowBtn() {
      return this.isPropertyLevel ? false : true;
    },
    emptyStateMessage() {
      return (this.isPropertyLevel && this.isPropertySelected) ? 'Add Documents from the Document Library.' : this.isPropertyLevel ? "Please select a Property to continue." : "There are no documents in your library. Click the button below to add a document.";
    },
    isPropertySelected() {
      return (this.property && this.property.id) ? true : false;
    },
    isDocuments() {
      return (this.getSettingsData && this.getSettingsData[this.level].length) ? true : false;
    },
    isDocumentsLoaded() {
      return this.documentsLoading ? false : true;
    }
  },
  methods: {
    ...mapActions({
      fetchDocuments: 'documentsStore/fetchDocuments',
      fetchSettingsData: 'documentsStore/fetchSettingsData',
      fetchUnSignedDocumentTypes: 'documentsStore/fetchUnSignedDocumentTypes',
      fetchDocumentsByProperty: 'propertiesStore/fetchDocumentsByProperty',
      deleteDocument: 'documentsStore/deleteDocument',
      defaultProperty: "propertiesStore/defaultProperty"
      
    }),
    ...mapMutations({
      setSettingsData: 'documentsStore/setSettingsData',
    }),


    // This method will be called only to fetch data for the first time.
    async fetchDocumentsList(level = this.level) {
      this.is_upload_document = false;
      this.documentsLoading = true;
      let limit = 20;
      let offset = 1
      let path = (this.isPropertyLevel) ? `&property_ids[]=${this.property.id}` : '';
      let data = {
        component: this,
        params: api.DOCUMENT + `?limit=${limit}&offset=${offset}&document_type=un-signed` + path,
        level: level
      }
      try {
        const emptyData = {
          this: this,
          level: level,
          data: []
        }
        await this.setSettingsData(emptyData)
        await this.fetchSettingsData(data)
      } catch(err) {
        this.showMessageNotification({ description: err });
      } finally {
        this.documentsLoading = false;
      }
    },
    async setMenuOption(option) {
      this.documentsLoading = true;
      this.level = option;
      if(this.isPropertyLevel) {
        const emptyData = {
          this: this,
          level: this.level,
          data: []
        }
        await this.setSettingsData(emptyData)
        if(this.isPropertySelected) {
          await this.fetchDocumentsList(this.level);
        }
      } else {
        await this.fetchDocumentsList(this.level);
      }
      this.documentsLoading = false;
    },
    async selectedProperty(property) {
      if(this.property != property){
        this.property = property;
        await this.fetchDocumentsList(this.level);
      }
    },
    uploadDocument(item = {}) {
      EventBus.$emit('HB-Navigation:DocumentTemplate', {
        ...item
      });
      // this.selected = item;
      // this.is_upload_document = true;
    },
    setDocumentsLoading(val) {
      this.documentsLoading = val;
    },

    async downloadGuide(name){
      let res = await api.get(this, api.DOCUMENT + 'guide/' + name);
      var buffer = Buffer.from(res.data);
      var blob = new Blob([buffer], {type: res.content_type });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = res.file_name;
      link.click();
    },
    
  },
}
</script>

<style lang="scss" scoped>
.heading {
  height: 21px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}
.description {
  font-size: 14px;
  color: #637381;
}
</style>